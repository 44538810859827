'use strict'

const _ = require('lodash')
const {getAllCompsInStructure} = require('../utils/flattenStructure')
const {nonPublicDataQueries} = require('./publicNamespaces')

const eliminateBlackListedQueries = comp => {
    if (!_.isObject(comp)) {return}

    _.forEach(nonPublicDataQueries, query => delete comp[query])
}

module.exports = (pageJson, viewMode) => {
    const isMobile = viewMode === 'mobile'
    const withParent = false
    const allComps = getAllCompsInStructure(pageJson.structure, isMobile, withParent)
    _.forEach(allComps, eliminateBlackListedQueries)
}
